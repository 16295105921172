import React, { useState, useEffect } from 'react';
import Link from 'next/link';
import Slider from 'react-slick';
import { useSelector } from 'react-redux';
import { remove_duplicates } from '../../../utils';
// import CourseCardFour from '../../cards/CourseCardFour';
import { selectTopSellings } from '../../../redux/slice/topSellingSlice';
import CourseCardFive from '../../cards/CourseCardFive';
import SyllabusForm from '../../common/SyllabusForm';

const CarouselSettings = {
    dots: false,
    infinite: false,
    arrows: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    slidesPerRow: 3,
    rows: 2,
    autoplay: false,
    autoplaySpeed: 3000,
    responsive: [
        {
            breakpoint: 1160,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                slidesPerRow: 3,
                rows: 2,
            },
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 1.3,
                slidesToScroll: 1,
                slidesPerRow: 1,
                rows: 1,
            },
        },
        {
            breakpoint: 500,
            settings: {
                arrows: false,
                slidesToShow: 1.08,
                slidesToScroll: 1,
                slidesPerRow: 1,
                rows: 1,
            },
        },
    ],
};

const CoursesList = ({ data: courses, id = 'featured-courses' }) => {
    const [allCourses, setAllCourses] = useState([]);

    const { courses: topSellings } = useSelector(selectTopSellings);

    useEffect(() => {
        if (courses && courses.length) {
            const _allCourses = remove_duplicates([...topSellings, ...courses]);

            const _live = _allCourses.filter(
                (item) => item?.Batches && item?.Batches.length && item?.Batches[0]?.sessionType === 1
            );

            const _recorded = _allCourses.filter(
                (item) => item?.Batches && item?.Batches.length && item?.Batches[0]?.sessionType === 2
            );

            const _finalResult = [..._live, ..._recorded];

            setAllCourses(_finalResult);
        }
    }, [courses, topSellings]);

    const [open, setOpen] = useState(false);
    const [course, setCourse] = useState({});

    if (!allCourses.length) {
        return <></>;
    }
    return (
        <>
            <div className='home-long-term-courses' id={id}>
                <div className='edu-section-gap kw-section-gap'>
                    <div className='container'>
                        <div className='d-flex align-items-center justify-content-between'>
                            <div className='section-title'>
                                <h3 className='title text-white mt-0'>Find a course to fast-forward your career</h3>
                            </div>
                            <div className='view-more-btn text-end align-items-center'>
                                <Link href={`/courses-and-workshops#explore`}>
                                    <a className='edu-btn kw-btn-blue'>
                                        Explore all courses
                                        <i className='icon-arrow-right-line-right'></i>
                                    </a>
                                </Link>
                            </div>
                        </div>

                        <Slider
                            className='slick-activation-wrapper course-activation-3 edu-slick-button'
                            {...CarouselSettings}
                        >
                            {allCourses &&
                                allCourses.length &&
                                allCourses.map((item, ind) => (
                                    <div
                                        className={`single-slick-card ${allCourses.length === 11 && ind === 10 ? 'card-11' : ''
                                            }`}
                                        key={ind}
                                    >
                                        <CourseCardFive data={item} setCourse={setCourse} setOpen={setOpen} />
                                    </div>
                                ))}
                        </Slider>

                        <div className='d-none align-items-center justify-content-center cta-container'>
                            <Link href={`/courses-and-workshops#explore`}>
                                <a className='cta-button rounded d-flex align-items-center justify-content-center'>
                                    Explore all courses
                                    <i className='icon-arrow-right-line-right fw-bold'></i>
                                </a>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <SyllabusForm open={open}
                setOpen={setOpen}
                course={{ name: course?.name, id: course?.id, kylasProductId: course?.kylasProductId }} />
        </>
    );
};

export default CoursesList;
