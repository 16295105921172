import React, { useEffect, useState } from 'react';
import Link from 'next/link';
import moment from 'moment';
import Slider from 'react-slick';
import { getBlogDetailsURL } from '../../utils';
import Image from 'next/image';

const CarouselSettings = {
  dots: false,
  infinite: false,
  arrows: false,
  speed: 500,
  slidesToShow: 2,
  slidesToScroll: 2,
  autoplay: false,
  autoplaySpeed: 3000,
  rows: 2,
  responsive: [
    {
      breakpoint: 1199,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 1.2,
        slidesToScroll: 1,
        slidesPerRow: 1,
        rows: 1,
        arrows: true,
      },
    },
  ],
};

const PostFour = ({ blogs }) => {
  const [initialIndex, setInitialIndex] = useState(1);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (window.innerWidth < 500) {
        setInitialIndex(0);
      } else {
        setInitialIndex(1);
      }
    }
  }, []);

  return (
    <div className='row'>
      <div className='col-lg-6 col-sm-12 col-12 main-card-container'>
        {blogs.slice(0, 1).map((item) => (
          <div key={item?.id}>
            <div className='edu-blog blog-overlay-transparent radius-small'>
              <div className='inner'>
                <div className='thumbnail'>
                  <Link
                    href={getBlogDetailsURL(item?.Blog?.title, item?.Blog.category, item?.Blog?.id, item?.Blog?.url)}
                    key={item.id}
                  >
                    <a>
                      <div className='image-wrapper'>
                        <Image
                          src={`${process.env.NEXT_PUBLIC_MEDIA_URL}/${item?.Blog?.image}`}
                          alt={item?.Blog?.title}
                          layout='fill'
                          loading='lazy'
                        />
                      </div>
                    </a>
                  </Link>
                </div>
                <div className='content'>
                  <div className='inner'>
                    <div className='eduvibe-status-list'>
                      <Link
                        href={getBlogDetailsURL(
                          item?.Blog?.title,
                          item?.Blog.category,
                          item?.Blog?.id,
                          item?.Blog?.url
                        )}
                        key={item.id}
                      >
                        <a className='eduvibe-status-transparent text-capitalize'> {item?.Blog?.category}</a>
                      </Link>
                    </div>
                    <h5 className='title'>
                      <Link
                        href={getBlogDetailsURL(
                          item?.Blog?.title,
                          item?.Blog.category,
                          item?.Blog?.id,
                          item?.Blog?.url
                        )}
                        key={item.id}
                      >
                        <a className='eduvibe-status-transparent'>{item?.Blog?.title}</a>
                      </Link>
                    </h5>
                    <ul className='blog-meta'>
                      <li>
                        <img
                          src='/kaarwan/icons/calendar.svg'
                          alt='calendar'
                          style={{
                            marginTop: '-8.5px',
                            width: '24px',
                            height: '24px',
                            filter: 'invert(1)',
                          }}
                        />
                        {moment(item?.Blog?.date).format('MMM DD, YYYY')}
                      </li>
                      <li>
                        <img
                          src='/kaarwan/icons/clock-6.svg'
                          alt='clock'
                          style={{
                            marginTop: '-8.5px',
                            width: '24px',
                            height: '24px',
                          }}
                        />
                        {item?.Blog?.readTime}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className='col-lg-6 col-sm-12 col-12  blogs-container slider-style-1'>
        <Slider className='row g-5' {...CarouselSettings}>
          {blogs.slice(initialIndex, 5).map((item, i) => (
            <div className={`col-lg-6 col-md-6 col-12 px-4 ${(i === 1 || i === 3) && 'p-4'} `} key={item.id}>
              <div className='edu-blog blog-type-4 radius-small'>
                <div className='inner'>
                  <div className='thumbnail'>
                    <Link
                      href={getBlogDetailsURL(item?.Blog?.title, item?.Blog.category, item?.Blog?.id, item?.Blog?.url)}
                    >
                      <a>
                        <div className='image-wrapper'>
                          <Image
                            src={`${process.env.NEXT_PUBLIC_MEDIA_URL}/${item?.Blog?.image}`}
                            alt={item?.Blog?.title}
                            layout='fill'
                            loading='lazy'
                          />
                        </div>
                      </a>
                    </Link>
                    <div className='top-position left-bottom'>
                      <Link
                        href={getBlogDetailsURL(
                          item?.Blog?.title,
                          item?.Blog.category,
                          item?.Blog?.id,
                          item?.Blog?.url
                        )}
                      >
                        <a className='eduvibe-status status-01 bg-primary-color text-capitalize'>
                          {item?.Blog?.category}
                        </a>
                      </Link>
                    </div>
                  </div>
                  <div className='content'>
                    <h6 className='title' title={item?.Blog?.title}>
                      <Link
                        href={getBlogDetailsURL(
                          item?.Blog?.title,
                          item?.Blog.category,
                          item?.Blog?.id,
                          item?.Blog?.url
                        )}
                      >
                        <a>{item?.Blog?.title} </a>
                      </Link>
                    </h6>
                    <ul className='blog-meta'>
                      <li className='d-flex align-items-center'>
                        <img
                          src='/kaarwan/icons/calendar.svg'
                          alt='calendar'
                          style={{
                            marginTop: '-2.5px',
                            width: '24px',
                            height: '24px',
                          }}
                        />
                        <span className='fw-bold text-dark'> {moment(item?.Blog?.date).format('MMM DD, YYYY')}</span>
                      </li>
                      <li className='d-flex align-items-center mx-4'>
                        <img
                          src='/kaarwan/icons/clock-6.svg'
                          alt='clock'
                          style={{
                            marginTop: '-2.5px',
                            width: '24px',
                            height: '24px',
                          }}
                        />
                        <span className='fw-bold text-dark'>{item?.Blog?.readTime}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default PostFour;
