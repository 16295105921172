import React from 'react';
import PostFour from '../../post/PostFour';
import { useSelector } from 'react-redux';
import { selectHomepageBlogs } from '../../../redux/slice/homepageBlogs';

const Explore = ({ classes }) => {
    const { success, blogs } = useSelector(selectHomepageBlogs);

    if (!success) {
        return <></>;
    }
    return (
        <div
            className={`explore-stories-container edu-blog-area edu-section-gap bg-image kw-section-gap ${
                classes ? classes : ''
            }`}
            style={classes && { backgroundImage: 'none' }}
        >
            <div className='container eduvibe-animated-shape'>
                <div className='row'>
                    <div className='col-lg-12'>
                        <div className='section-title'>
                            {classes ? (
                                <>
                                    <span className='pre-title text-light'>Explore</span>
                                    <h3 className='title text-light'>Our Stories</h3>
                                </>
                            ) : (
                                <>
                                    <span className='pre-title'>blogs</span>
                                    <h3 className='title'>Explore our newest and most-read blog posts.</h3>
                                </>
                            )}
                        </div>
                    </div>
                </div>
                <div className='row g-5 card-items'>
                    <PostFour blogs={blogs} />
                </div>
            </div>
        </div>
    );
};

export default Explore;
