import http from './httpService';

/**************************************
  Kaarwan web services

  1.  Email subscription ->Checked
  2.  Send user contact details to kaarwan ->Checked
  3.  Get callback request from kaarwan ->Checked
   
***************************************/

// Add user subscription (Email subscription)
export function addSubscription(data) {
  return http.post(`/api/kaarwan/subscribe`, data);
}

// Contact Us form (Contact Us page)
export function sendContactUsDetails(data) {
  return http.post(`/api/kaarwan/contact`, data);
}

// Callback request (Callback Modal Form)
export function callbackRequest(data) {
  return http.post('/api/callback', data);
}

// Campaign Lead
export function campaignLeads(data) {
  return http.post('/api/campaign/leads', data);
}


// Hire From Us Email
export function hireUsSubmission(data) {
  return http.post(`/api/company/hire-us`, data)
}