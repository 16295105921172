/* eslint-disable @next/next/no-img-element */
import React from 'react';

const CompanyLogos = (props) => {
  const { logos, title, subTitle = '56+ more Companies', classess } = props;
  const _classes = `landing-demo-features bg-color-white company-logo  ${classess} kw-section-gap`;
  if (!logos.length) {
    return <></>;
  }
  return (
    <div className={_classes}>
      <div className='container eduvibe-animated-shape'>
        {title ? <h5 className='color-dark fw-bold text-center mb-0'>{title}</h5> : null}


        <picture>
          <source media='(max-width:580px)' srcSet='/constant/kaarwan-students-work-for-sm(1).avif' loading='lazy' />
          <img src='/constant/kaarwan-students-work-for-lg(1).avif' alt='kaarwan-student-work-for-the-companies' loading='lazy' />
        </picture>

        <div className='row'>
          <div className='col-lg-12'>
            <div className='pre-section-title text-center'>
              <span className='color-dark fw-bold'>{subTitle}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyLogos;
