import React from "react";
import CompanyLogos from "../../common/CompanyLogos";

const BASE_DIR = "/constant/homepage/logos/participant/";
const LOGO_LIST = [
  {
    id: 1,
    filepath: BASE_DIR + "accenture.svg",
    title: "Accenture",
    classess: "accenture-logo",
  },
  {
    id: 2,
    filepath: BASE_DIR + "byjus.avif",
    title: "Byjus",
    classess: "byjus-logo",
  },
  {
    id: 3,
    filepath: BASE_DIR + "cannon.avif",
    title: "Canon Design",
    classess: "cannon-logo",
  },
  {
    id: 4,
    filepath: BASE_DIR + "epistle-comm.avif",
    title: "Epistle Communication",
    classess: "epistle-logo",
  },
  {
    id: 5,
    filepath: BASE_DIR + "flipkart.avif",
    title: "Flipkart",
    classess: "flipart-logo",
  },
  {
    id: 6,
    filepath: BASE_DIR + "infosys.svg",
    title: "Infosys",
    classess: "infosys-logo",
  },
  {
    id: 7,
    filepath: BASE_DIR + "makemytrip.avif",
    title: "Make My Trip",
    classess: "mmt-logo",
  },
  {
    id: 8,
    filepath: BASE_DIR + "razorpay.svg",
    title: "Razorpay",
    classess: "razorpay-logo",
  },
  {
    id: 9,
    filepath: BASE_DIR + "smallcase.avif",
    title: "Smallcase",
  },
  {
    id: 10,
    filepath: BASE_DIR + "tcs.avif",
    title: "TCS",
    classess: "tcs-logo",
  },
  {
    id: 11,
    filepath: BASE_DIR + "tech-mahindra.avif",
    title: "Tech Mahindra",
    classess: "tech-mahindra-logo",
  },
  {
    id: 12,
    filepath: BASE_DIR + "vedantu.avif",
    title: "Vedantu",
    classess: "vedantu-logo",
  },
];
const ParticipantsCompanies = () => {
  return <CompanyLogos logos={LOGO_LIST} classess="pb--130" title="Companies our students work for" />;
};

export default ParticipantsCompanies;
