import Image from 'next/image';
import React, { useState } from 'react';
import Slider from 'react-slick';

const data = [
    {
        name: 'Diksha Pilania',
        details:
            'It was a great opportunity to be able to share my knowledge with budding artists and designers. The sessions were well-planned and gave me a good timeline to focus on teaching. The team was understanding and helpful in terms of planning and navigating through the sessions.',
        designation: 'Interdisciplinary Designer. Co founder at Vanvaasi',
        image: '/constant/mentors/Diksha-Pilania.png',
        rating: 5,
    },
    {
        name: 'Aneree Satra',
        details:
            'With Kaarwan we are able to help bridge the gap between education and field practise, get them prepared as per industry trends. The teaching journey with Kaarwan has been very smooth and they are always there to help the instructors whenever required, open to our suggestions and give the liberty of teaching how we want to.',
        designation: 'Architect, Co-Founder at QuickSO',
        image: '/constant/mentors/Aneree-Satra.png',
        rating: 5,
    },
    {
        name: 'Saili Sawantt',
        details:
            'They are a fun and engaging team, while staying rooted to their moto of uplifting the learning space in design. Teaching with them has given me the access to balance the practical aspect of Architectural Journalism along with the theory it needs.',
        designation: 'Architect, Architectural Writer',
        image: '/constant/mentors/Saili-Sawantt.png',
        rating: 5,
    },
    {
        name: 'Tanvi Bobhate',
        details:
            'Very insightful! The connection and conversations during lectures have helped me and the students learn from a different perspective. The whole Kaarwan team has made the operational/backend process smoother, so I can focus on the content.',
        designation: 'Architect, Sustainability Consultant',
        image: '/constant/mentors/Tanvi-Bobhate.png',

        rating: 5,
    },

    {
        name: 'Savii Barroliya',
        details:
            'After realising the importance of Vastu and the huge Gap that exists between what is being taught at Architecture & Interior Colleges to what happens in reality, I wanted to enlighten more people around the subject and the collaboration with Kaarwan has helped me achieve that.',
        designation: 'Architect, Vastu Expert',
        image: '/constant/mentors/Savii-Barroliya.png',

        rating: 5,
    },
    {
        name: 'Kiran Hirani',
        details:
            'I am soo glad to be a part of Team Kaarwan. The teaching experience has just been amazing. A super supportive team and great management can really do wonders. It feels great to share my practical experience with all the participants and spread my radar of knowledge.',
        designation: 'Architect, Interior Stylist',
        image: '/constant/mentors/Kiran-Thadani.png',

        rating: 5,
    },
];

const Mentors = () => {
    const [setting] = useState({
        dots: false,
        infinite: false,
        arrows: true,
        speed: 1000,
        slidesToShow: 1,
        slidesPerRow: 2,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 3000,
        initialSlide: 0,
        rows: 3,
        responsive: [
            {
                breakpoint: 1199,
                settings: {
                    slidesToShow: 1,
                    slidesPerRow: 2,
                    slidesToScroll: 1,
                    rows: 3,
                },
            },
            {
                breakpoint: 767,
                settings: {
                    dots: false,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    slidesPerRow: 1,
                    rows: 3,
                    arrows: true,
                },
            },
            {
                breakpoint: 500,
                settings: {
                    dots: false,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    slidesPerRow: 1,
                    rows: 1,
                    arrows: true,
                },
            },
        ],
    });

    return (
        <div className='mentors-container edu-section-gap bg-white kw-section-gap'>
            <div className='container eduvibe-animated-shape'>
                <div className='row'>
                    <div className={`section-title text-center`} style={{ marginBottom: '64px' }}>
                        <span className='pre-title'>MENTORS</span>
                        <h3 className={`title`}>
                            Hear from our experienced mentors about their journey and insights at Kaarwan
                        </h3>
                    </div>

                    <div className='slider-style-1'>
                        <Slider
                            className='slick-activation-wrapper mentor-cards-container course-activation-3 edu-slick-button'
                            {...setting}
                        >
                            {data.map((item, i) => (
                                <div className='mentor-card' key={i}>
                                    <div className='inner' style={{ marginLeft: 'auto' }}>
                                        <div className={`d-flex align-items-start`}>
                                            <Image
                                                src={`/kaarwan/avatar/${i % 2 ? 'quote-2.png' : 'quote.png'}`}
                                                alt='quote-icon'
                                                width={30}
                                                height={33}
                                                objectFit='contain'
                                            />
                                            <p className='description m-0'>{item.details}</p>
                                        </div>
                                        <div
                                            className='client-info d-flex align-items-center'
                                            style={{ gap: '0 16px', marginTop: '16px' }}
                                        >
                                            <div className='thumbnail overflow-hidden rounded-circle'>
                                                <Image
                                                    src={item.image}
                                                    alt={item.image}
                                                    layout='fill'
                                                    unoptimized={true}
                                                />
                                            </div>
                                            <div className='content'>
                                                <h6 className='title p-0 m-0 name'>{item.name}</h6>
                                                <span className='text-muted designation'>{item.designation}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Mentors;
