import React, { useEffect, useState } from 'react';
import Head from 'next/head';
import dynamic from 'next/dynamic';
import { useDispatch, useSelector } from 'react-redux';
import { selectMasterclases } from '../redux/slice/masterclassSlice';
import { selectCourses } from '../redux/slice/coursesSlice';
import { fetchHomepageCustomList, selectCustomCourseList } from '../redux/slice/customCourseList';
import { selectCurrency, setCurrencyCode } from '../redux/slice/currencySlice';
import { fetchBannerData, selectTopBanner } from '../redux/slice/topBannerSlice';
import { fetchHomepageBlogs, selectHomepageBlogs } from '../redux/slice/homepageBlogs';
import HeaderBanner from '../components/common/HeaderBanner';
import CommonNavbar from '../components/common/header/CommonNavbar';
import Hero from '../components/presentation/home/Hero';
import Companies from '../components/common/Companies';
import AlumniNetwork from '../components/presentation/home/AlumniNetwork';
import Explore from '../components/presentation/home/Explore';
import Mentors from '../components/presentation/home/Mentors';
import ParticipantsCompanies from '../components/presentation/home/ParticipantsCompanies';
import MasterclassesCategoryWise from '../components/presentation/home/MasterclassesCategoryWise';
import MasterclassesListView from '../components/presentation/home/MasterclassesListView';
import CoursesListView from '../components/presentation/home/CoursesListView';
import GeneralFAQ from '../components/common/GeneralFAQ';
import Footer from '../components/common/footer/Footer';
import WhatSetUsApart from '../components/presentation/home/WhatSetUsApart';
import HeroSection from '../components/presentation/home/HeroSection';
import BecomeInstructor2 from '../components/presentation/home/BecomeInstructor2';
import EmailSubscriptionVer2 from '../components/common/EmailSubscriptionVer2';
import CoursesList from '../components/presentation/home/CoursesList';
import ThumbnailListing from '../components/presentation/home/ThumbnailListing';
import Testimonials from '../components/presentation/home/Testimonials';

const CounsellingForm = dynamic(() => import('../components/common/CallbackFormVer2'), { ssr: false });

const metaTags = {
    title: 'Upskill Your Architectural Design Career | Certified with Kaarwan',
    description:
        'Unlock your career potential with our result oriented & affordable upskilling programs for architects, interior designers, graphic designers & UI-UX designers. Learn from industry experts and advance your career with Kaarwan.',
    keywords:
        'Architecture courses,Interior design courses,Graphic design courses,UI/UX design courses,Career development,Upskilling,Professional development,Online learning,Design education,Architectural technology',
    image: 'https://www.kaarwan.com/kaarwan/static-seo-img/kaarwan-homepage.webp',
    url: 'https://www.kaarwan.com',
};

const Index = () => {
    const dispatch = useDispatch();

    const { success: _currencyType } = useSelector(selectCurrency);

    useEffect(() => {
        if (!_currencyType) {
            dispatch(setCurrencyCode());
        }
    }, [dispatch, _currencyType]);

    const { masterclasses } = useSelector(selectMasterclases);
    const { courses: longTermCourses } = useSelector(selectCourses);

    const { success: bannerSuccess } = useSelector(selectTopBanner);
    const { success: homepageBlogsSuccess } = useSelector(selectHomepageBlogs);

    const { success: customListingSuccess, data: sections } = useSelector(selectCustomCourseList);

    const [callbackOpen, setCallbackOpen] = useState(false);

    useEffect(() => {
        if (typeof window !== 'undefined') {
            if (window.innerWidth > 768) {
                setCallbackOpen(true);
            }
        }
    }, []);

    useEffect(() => {
        if (!bannerSuccess) {
            dispatch(fetchBannerData());
        }
        if (!homepageBlogsSuccess) {
            dispatch(fetchHomepageBlogs());
        }
        if (!customListingSuccess) {
            dispatch(fetchHomepageCustomList());
        }
    }, [bannerSuccess, homepageBlogsSuccess, dispatch, customListingSuccess]);

    const [open, setOpen] = useState(false);

    return (
        <>
            <Head>
                <title>{metaTags.title}</title>

                <meta name='title' content={metaTags.title} />
                <meta name='description' content={metaTags.description} />
                <meta name='keywords' content={metaTags.keywords} />

                <meta property='og:title' content={metaTags.title} />
                <meta property='og:description' content={metaTags.description} />
                <meta property='og:keywords' content={metaTags.keywords} />
                <meta property='og:image' content={metaTags.image} />
                <meta property='og:url' content={process.env.NEXT_PUBLIC_SITE_URL} />
                <meta property='og:type' content='education' />

                <meta property='twitter:card' content='summary_large_image' />
                <meta property='twitter:url' content={metaTags.url} />
                <meta property='twitter:title' content={metaTags.title} />
                <meta property='twitter:description' content={metaTags.description} />
                <meta property='twitter:image' content={metaTags.image} />

                <link rel='canonical' href={metaTags.url} />
                <link rel='image_src' href={metaTags.image} />
            </Head>
            <main>
                <div className='home-container'>
                    <HeaderBanner headerId='kwn-sticky-header-1' />
                    <CommonNavbar classes='homepage-header'  />
                    <HeaderBanner headerId='kwn-sticky-header-2' btnClose />


                    <div className='home-hero-section-desktop'>
                        <HeroSection />
                    </div>
                    <div className='home-hero-section-mobile'>
                        <Hero />
                    </div>
                    <Companies />
                    <div id='long-term-courses'>
                        <CoursesList data={longTermCourses ? longTermCourses : []} />
                    </div>
                    <WhatSetUsApart />

                    <MasterclassesCategoryWise id='featured-courses' data={masterclasses ? masterclasses : []} />

                    {sections && sections.length
                        ? Array.from(sections).map((item) => (
                            <div key={item?.id}>
                                <>
                                    {item?.cardType === 1 ? (
                                        <>
                                            {item?.type === 1 ? (
                                                <MasterclassesListView data={item?.workshops} sectionData={item} />
                                            ) : (
                                                <CoursesListView data={item?.workshops} sectionData={item} />
                                            )}
                                        </>
                                    ) : (
                                        <ThumbnailListing data={item} />
                                    )}
                                </>
                            </div>
                        ))
                        : null}
                    <AlumniNetwork />
                    <Testimonials />
                    <Mentors />
                    <ParticipantsCompanies />
                    <BecomeInstructor2 />
                    <Explore />
                    <GeneralFAQ />
                    <EmailSubscriptionVer2 />
                    <Footer classes='eduvibe-footer-one' />
                    <CounsellingForm showChat={false} isCallbackOpen={callbackOpen} open={open} setOpen={setOpen} utmType={9} defer />
                </div>
            </main>
        </>
    );
};

export default Index;
