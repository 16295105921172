import Link from 'next/link';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { MdArrowForward } from 'react-icons/md';
import { IoClose } from 'react-icons/io5';
import { selectTopBanner } from '../../redux/slice/topBannerSlice';

const HeaderBanner = ({ btnClose = false, headerId = '' }) => {
    const { content, link } = useSelector(selectTopBanner);

    const stickyHideHandle = () => {
        if (typeof window !== 'undefined') {
            const stickyBar = document.querySelector('#kwn-sticky-header-2');
            stickyBar.classList.add('hide');
            stickyBar.classList.remove('show');
        }
    };

    const handleStickyBar = () => {
        if (typeof window !== 'undefined') {
            const stickyBar1 = document.querySelector('#kwn-sticky-header-1');
            const stickyBar2 = document.querySelector('#kwn-sticky-header-2');
            if (stickyBar1 && stickyBar2) {
                if (stickyBar1.getBoundingClientRect().bottom < 0) {
                    if (!stickyBar2.classList.contains('hide')) {
                        stickyBar2.classList.add('show');
                    }
                } else {
                    stickyBar2.classList.remove('show');
                }
            }
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleStickyBar);
        return () => {
            window.removeEventListener('scroll', handleStickyBar);
        };
    }, []);

    if (!link) {
        return <></>;
    }

    return (
        <div className='kwn-sticky-header' id={headerId}>
            <Link href={link}>
                <a className='text-decoration-none'>
                    <div className='kwn-header-banner d-flex align-items-center justify-content-center text-dark'>
                        <span className='two-line-title text-white text-decoration-none'>{content}</span>

                        <div className='d-flex align-items-center text-white ms-3 btn-enroll text-nowrap'>
                            <span className='text-decoration-underline me-1'>Enroll Now</span>
                            <MdArrowForward color='#ffffff' />
                        </div>
                    </div>
                </a>
            </Link>
            {btnClose ? (
                <button type='button' className='btn-banner-close bg-transparent border-0' onClick={stickyHideHandle}>
                    <IoClose size={28} color='#ececec' />
                </button>
            ) : null}
        </div>
    );
};

export default HeaderBanner;
