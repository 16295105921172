/* eslint-disable @next/next/no-img-element */
import Image from 'next/image';
import React from 'react';

export const LOGOS = [
    { id: 1, logo: '1-omkar.png', title: 'OMKAR' },
    {
        id: 2,
        logo: '2-frame.png',
        title: 'Archohm Consults Pvt Ltd',
        classes: 'd-flex align-items-center justify-content-center archohm',
    },
    { id: 3, logo: '3-aecom.png', title: 'AECOM' },
    { id: 4, logo: '4-housing.png', title: 'HOUSING.COM' },
    { id: 5, logo: '5-yash-raj-films.png', title: 'YASH RAJ FILMS' },
    { id: 6, logo: '6-adani.png', title: 'ADANI' },
    { id: 7, logo: '7-godrej.png', title: 'GODREJ' },
    { id: 8, logo: '8-ultra-confidential.png', title: 'Ultraconfidentiel' },
    { id: 9, logo: '9-cannon-desig.png', title: 'CANNON DESIGN' },
    { id: 10, logo: '10-flipkart.png', title: 'FLIPKART' },
    { id: 11, logo: '11-zee.png', title: 'ZEE' },
    { id: 12, logo: '12-disney-hotstar.png', title: 'DISNEY HOTSTAR' },
    { id: 13, logo: '13-shimizu-corporation.png', title: 'SHIMIZU CORPORATION' },
    { id: 14, logo: '14-frame.png', title: 'Playment' },
    { id: 15, logo: '15-livspace.png', title: 'LIVSPACE' },
    { id: 16, logo: '16-world-architecture-community.png', title: 'WORLD-ARCHITECTURE' },
    { id: 17, logo: '17-frame.png', title: 'Restore Design Pvt Ltd' },
    { id: 18, logo: '18-jll.png', title: 'JLL' },
    { id: 19, logo: '19-isb.png', title: 'ISB' },
    { id: 20, logo: '20-dar.png', title: 'Dar Al-Handasah (Shair and Partners)' },
    { id: 21, logo: '21-myinnospace.png', title: 'MYINNOSPACE' },
];

const Companies = ({ isRevit }) => {
    return (
        <div className='container logos-main-container'>
            <div className='text-center'>
                <span className='fw-bold text-dark text-decoration-none title'>Kaarwan experts are from</span>
            </div>
            <div className='logos-lg-container'>
                <Image
                    src={isRevit ? '/constant/revit-images/revit-page-logos.avif' : '/kaarwan/kaarwan-companies-logo-lg.avif'}
                    alt='kaarwan-comapnies-logo'
                    width={1170}
                    height={373.25}
                    unoptimized
                    loading='lazy'
                />
            </div>
            <div className='logos-sm-container'>
                <Image
                    src='/kaarwan/kaarwan-companies-logo-sm.avif'
                    alt='kaarwan-comapnies-logo'
                    width={412}
                    height={484.45}
                    unoptimized
                    loading='lazy'
                />
            </div>
            <div className='text-center'>
                <span className='caption text-dark'>76+ more companies</span>
            </div>
        </div>
    );
};

export default Companies;
