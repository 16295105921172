import Image from 'next/image';
import Link from 'next/link';
import React from 'react';

const BASE_DIR = '/constant/homepage/become-instructor';

const INSTRUCTOR_IMAGES = [
    {
        id: 1,
        image: 'raj-gandhi',
    },
    {
        id: 2,
        image: 'geo-jerwin',
    },
    {
        id: 3,
        image: 'ashita-amin',
    },
    {
        id: 4,
        image: 'saili-sawantt',
    },
];

const ITEMS = [
    {
        id: 1,
        text: 'Build your brand',
    },
    {
        id: 2,
        text: 'Supplement your income',
    },
    {
        id: 3,
        text: 'Help to shape future',
    },
    {
        id: 4,
        text: 'Inspire learners',
    },
    {
        id: 5,
        text: 'Networking Opportunities',
    },
];

const BecomeInstructor2 = () => {
    return (
        <div className='home-become-instructor-section edu-section-gap kw-section-gap'>
            <div className='container'>
                <div className='header-sm'>
                    <div className='section-title'>
                        <h3 className='title text-dark'>Become an instructor!</h3>
                    </div>
                </div>
                <div className='row g-5 d-flex align-items-center'>
                    <div className='col-lg-5 col-sm-12'>
                        <div className='row g-4 h-100 d-flex align-items-center'>
                            {INSTRUCTOR_IMAGES.map((item, ind) => (
                                <div className='col-lg-6 col-6' key={ind}>
                                    <div className='instructor-image-wrapper'>
                                        <Image
                                            src={BASE_DIR + `/${item.image}.webp`}
                                            alt={item.image}
                                            layout='fill'
                                            unoptimized={true}
                                        />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className='col-lg-7 col-sm-12'>
                        <div className='ps-5'>
                            <div className='header-lg'>
                                <div className='section-title'>
                                    <h3 className='title text-dark'>Become an instructor!</h3>
                                </div>
                            </div>
                            <p className='desc'>
                                Join Kaarwan to share your expertise with tomorrow's changemakers. Design your
                                curriculum, with full support from our team, and deliver workshops to hundreds of
                                learners worldwide.
                            </p>

                            <div className='row'>
                                {ITEMS.map((item, ind) => (
                                    <div className={`col-lg-4 ${ind === 4 ? 'col-12' : 'col-6'}`} key={item.id}>
                                        <div className='text-icon-wrapper'>
                                            <Image
                                                src={BASE_DIR + `/icon-${ind + 1}.png`}
                                                alt='icon-thumb'
                                                width={40}
                                                height={40}
                                                objectFit='contain'
                                                quality={99}
                                            />
                                            <p className='text-nowrap'>{item.text}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <Link href='/become-instructor'>
                                <a>
                                    <button
                                        type='button'
                                        className='kw-secondary-btn d-flex align-items-center border-0 w-100 justify-content-center interested-link kw-btn-orange'
                                    >
                                        Start Teaching with us
                                        <i className='icon-arrow-right-line-right fw-bolder'></i>
                                    </button>
                                </a>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BecomeInstructor2;
