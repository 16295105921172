import React from 'react';
import Marquee from 'react-fast-marquee';

const AlumniNetwork = () => {
  return (
    <div className='alumni-network-container edu-section-gap container kw-section-gap alumni-network'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-5 col-sm-12 col-12 alumni-header'>
            <div className='section-title'>
              <span className='pre-title'>our alumni network</span>
              <h3 className='title'>Your love inspires us, every minute</h3>
            </div>

            <p className='description'>
              Kaarwan workshops have helped me understand how to apply the theoretical aspects in the practical industry
            </p>
            <h5>More than 44,600 learner love exploring new topics with us</h5>
            <div className='d-flex gap-5 align-items-center alumni-counter'>
              <div>
                <h1 className='counter'>93%</h1>
                <p className='desc'>Participants complete the courses successfully</p>
              </div>
              <div>
                <h1 className='counter'>9/10</h1>
                <p className='desc'>Participants reported better learning outcomes Read what they have to say</p>
              </div>
            </div>
          </div>
          <div className='col-lg-7 col-sm-12 col-12'>
            <div className='users-photo-list'>
              <Marquee gradient={false} speed={90}>
                <img
                  src='/constant/homepage/alumni/Group-28.webp'
                  alt='student'
                  // loading='lazy'
                  className='student-photo'
                />
                <img
                  src='/constant/homepage/alumni/Group-29.webp'
                  alt='student'
                  // loading='lazy'
                  className='student-photo'
                />
                <img
                  src='/constant/homepage/alumni/Group-31.webp'
                  alt='student'
                  // loading='lazy'
                  className='student-photo'
                />
                <img
                  src='/constant/homepage/alumni/Group-32.webp'
                  alt='student'
                  // loading='lazy'
                  className='student-photo'
                />
                <img
                  src='/constant/homepage/alumni/Group-33.webp'
                  alt='student'
                  // loading='lazy'
                  className='student-photo'
                />
                <img
                  src='/constant/homepage/alumni/Group-34.webp'
                  alt='student'
                  // loading='lazy'
                  className='student-photo'
                />
              </Marquee>
              <Marquee gradient={false} speed={90} className='slider-two'>
                <img
                  src='/constant/homepage/alumni/Group-35.webp'
                  alt='student'
                  // loading='lazy'
                  className='student-photo'
                />
                <img
                  src='/constant/homepage/alumni/Group-36.webp'
                  alt='student'
                  // loading='lazy'
                  className='student-photo'
                />
                <img
                  src='/constant/homepage/alumni/Group-37.webp'
                  alt='student'
                  // loading='lazy'
                  className='student-photo'
                />
                <img
                  src='/constant/homepage/alumni/Group-38.webp'
                  alt='student'
                  // loading='lazy'
                  className='student-photo'
                />
                <img
                  src='/constant/homepage/alumni/Group-39.webp'
                  alt='student'
                  // loading='lazy'
                  className='student-photo'
                />
                <img
                  src='/constant/homepage/alumni/Group-40.webp'
                  alt='student'
                  // loading='lazy'
                  className='student-photo'
                />
              </Marquee>
              <Marquee gradient={false} speed={90}>
                <img
                  src='/constant/homepage/alumni/Group-41.webp'
                  alt='student'
                  // loading='lazy'
                  className='student-photo'
                />
                <img
                  src='/constant/homepage/alumni/Group-42.webp'
                  alt='student'
                  // loading='lazy'
                  className='student-photo'
                />
                <img
                  src='/constant/homepage/alumni/Group-43.webp'
                  alt='student'
                  // loading='lazy'
                  className='student-photo'
                />
                <img
                  src='/constant/homepage/alumni/Group-44.webp'
                  alt='student'
                  // loading='lazy'
                  className='student-photo'
                />
                <img
                  src='/constant/homepage/alumni/Group-45.webp'
                  alt='student'
                  // loading='lazy'
                  className='student-photo'
                />
                <img
                  src='/constant/homepage/alumni/Group-46.webp'
                  alt='student'
                  // loading='lazy'
                  className='student-photo'
                />
              </Marquee>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AlumniNetwork;
