import Image from 'next/image';
import Link from 'next/link';
import React from 'react';
import CONSTANTS from '../../../utils/constants';

const Hero = () => {
    const ITEMS = [
        {
            id: 4,
            title: "Upto <span>350%</span> salary <i class='line-break'></i> hike for Architects",
            icon: 'salary-hike.svg',
            classes: 'item-1',
        },
        {
            id: 3,
            title: "<span>Job support</span> <i class='line-break'></i> Certification programs",
            icon: 'job-support.svg',
            classes: 'item-2',
        },
        {
            id: 1,
            title: "Tech upskilling for <i class='line-break'></i> <span>Architects</span>",
            icon: 'skill-development.svg',
            classes: 'item-3',
        },
        {
            id: 2,
            title: "Shift to <span>High-paying</span> <i class='line-break'></i> Design career",
            icon: 'design-career.svg',
            classes: 'item-4',
        },
    ];
    return (
        <div className='home-hero mobile-version'>
            <div className='container hero-background'>
                <div className='main-section edu-section-gap kw-section-gap'>
                    <div className='row d-flex align-items-center'>
                        <div className='col-lg-7 col-md-12'>
                            <p className='description rounded-pill mx-auto'>
                                🏆 Helped over +44,600 designers worldwide!
                            </p>

                            <h1 className='title'>
                                <Image
                                    src='/constant/kaarwan-homepage-hero-image.webp'
                                    width={265}
                                    height={118}
                                    alt='kaarwan-hero-section-image'
                                    priority
                                />
                            </h1>

                            <div className='desktop-cta'>
                                <Link href='/courses-and-workshops#explore'>
                                    <a className='courses-cta kw-btn-orange d-flex align-items-center justify-content-center text-nowrap'>
                                        Explore Courses
                                    </a>
                                </Link>
                            </div>
                        </div>
                        <div className='col-lg-5 col-md-12'>
                            <div className='background-container'>
                                <div className='items-container'>
                                    {ITEMS.map((item) => (
                                        <div
                                            className={`item-bar ${item.classes} d-flex align-items-center text-nowrap`}
                                            key={item.id}
                                        >
                                            <img src={`/constant/homepage/icons/${item.icon}`} alt='' />
                                            <p dangerouslySetInnerHTML={{ __html: item.title }} className='m-0 p-0'></p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className='mobile-cta'>
                                <Link href='/courses-and-workshops#explore'>
                                    <a className='courses-cta kw-btn-orange d-flex align-items-center justify-content-center text-nowrap'>
                                        Explore Courses
                                    </a>
                                </Link>
                            </div>
                        </div>
                        <div className='bottom-badge d-flex align-items-center justify-content-between px-4'>
                            <div className='google-badge'>
                                <Image
                                    src='/kaarwan/google-for-education-badge.png'
                                    alt='google-for-education-badge'
                                    width={114}
                                    height={74}
                                    objectFit='contain'
                                />
                            </div>
                            <div className='facebook-rating'>
                                <div>
                                    <a href={CONSTANTS.KWN_FB_PAGE} target='_blank' rel='noopener noreferrer'>
                                        <Image
                                            src='/kaarwan/facebook-logo.png'
                                            width={88}
                                            height={18}
                                            alt='facebook-logo'
                                        />
                                    </a>
                                </div>
                                <div className='d-flex align-items-center'>
                                    <span className='me-2 rating'>4.9</span>
                                    <Image src='/kaarwan/ratings-dark.png' alt='course-rating' width={69} height={12} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Hero;

