import Link from 'next/link';
import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import { getWorkshopURL } from '../../../utils';
import Image from 'next/image';
import { useRouter } from 'next/router';

const videoCarouselSettings = {
    dots: false,
    infinite: false,
    arrows: true,
    speed: 500,
    autoplay: false,
    autoplaySpeed: 3000,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [
        {
            breakpoint: 1199,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
            },
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 2.3,
                slidesToScroll: 2,
            },
        },
        {
            breakpoint: 500,
            settings: {
                arrows: false,
                slidesToShow: 1.2,
                slidesToScroll: 1,
            },
        },
        {
            breakpoint: 360,
            settings: {
                arrows: false,
                slidesToShow: 1.1,
                slidesToScroll: 1,
            },
        },
    ],
};

const ThumbnailListing = ({ data }) => {
    const [thumbs, setThumbs] = useState([]);

    useEffect(() => {
        if (data?.workshops && data?.workshops.length) {
            setThumbs(Array.from(data?.workshops));
        }
    }, [data?.workshops]);

    const router = useRouter();
    let _params = '';
    Object.entries(router.query)
        .filter((item) => item[0] !== 'paymentId')
        .filter((item) => item[0] !== 'id')
        .forEach((item) => {
            _params += `&${item[0]}=${item[1]}`;
        });

    return (
        <div
            className='kwn-thumbnail-listing edu-section-gap kw-section-gap'
            style={{ background: `${data?.sectionBgColor}` }}
        >
            <div className='container'>
                <div className='section-title'>
                    <h3 className='title' style={{ color: data?.textColor }}>
                        {data?.title}
                    </h3>
                </div>
            </div>
            <div className='container list-container'>
                {thumbs && thumbs.length ? (
                    <div className='thumbnails-list slider-style-2 mt-5'>
                        <Slider {...videoCarouselSettings}>
                            {[...thumbs].map((item, ind) => (
                                <Link
                                    href={getWorkshopURL(item?.type, item?.name, item?.id, item?.url, _params)}
                                    key={ind}
                                >
                                    <a>
                                        <div className='thumbnail-card overflow-hidden'>
                                            <div className='image-wrapper w-100 h-100'>
                                                <Image
                                                    src={`${process.env.NEXT_PUBLIC_MEDIA_URL}/${item?.thumbImage}`}
                                                    alt={item?.name}
                                                    layout='fill'
                                                    // loading='lazy'
                                                />
                                            </div>
                                        </div>
                                    </a>
                                </Link>
                            ))}
                        </Slider>
                    </div>
                ) : null}
            </div>
        </div>
    );
};

export default ThumbnailListing;
