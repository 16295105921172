import React from 'react';
import Slider from 'react-slick';
import CourseCardThree from '../../cards/CourseCardThree';

const CarouselSettings = {
  dots: false,
  infinite: false,
  arrows: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 4,
  autoplay: false,
  autoplaySpeed: 3000,
  responsive: [
    {
      breakpoint: 1199,
      settings: {
        slidesToShow: 3.1,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 2.1,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 500,
      settings: {
        arrows: false,
        slidesToShow: 1.1,
        slidesToScroll: 1,
      },
    },
  ],
};

const MasterclassesListView = ({ data: courses, sectionData }) => {
  if (!courses.length) {
    return <></>;
  }
  return (
    <div className='masterclasses-list-view' style={{ backgroundColor: sectionData?.sectionBgColor }}>
      <div className='edu-section-gap kw-section-gap'>
        <div className='container'>
          <div className='section-title'>
            <h3 className='title' style={{ color: sectionData?.textColor }}>
              {sectionData?.title}
            </h3>
          </div>
          <Slider className='slick-activation-wrapper course-activation-3 edu-slick-button' {...CarouselSettings}>
            {courses &&
              courses.length &&
              courses.map((item) => (
                <div className='single-slick-card' key={item?.id}>
                  <CourseCardThree data={item} />
                </div>
              ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default MasterclassesListView;
